@import '../settings';

.buttonClose {
  background-color: $color-4;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 20px;

  > .cross {
    height: 9px;
    left: 50%;
    position: absolute;
    stroke: $color-7;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
  }
}

@import '../settings';

.controlPanel {
  left: 0;
  position: absolute;
  top: $header-height-mobile;
  width: 100%;
  z-index: 10;
  max-height: none;
  overflow: visible;

  &._noHeader {
    top: 0;
  }

  @media #{$mq-tablet} {
    overflow: auto;
    background: transparent;
    flex: 0 1 auto;

    // cancels pointer-events: none of c-map-panels
    pointer-events: auto;
    position: relative;
    top: 0;
    width: 100%;
  }

  > .bgWrapper {
    background: $color-32;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
    max-height: 55vh;

    @media #{$mq-tablet} {
      overflow: visible;
      max-height: none;
      margin-left: $control-panel-left-offset;
    }
  }

  .mapOptions {
    background-color: $color-33;
  }

  .resumeDisplay {
    width: 100%;
    border-bottom: 1px solid rgba($color-34, 0.2);
    display: none;
    flex-direction: column;
    padding: 16px 0 16px 22px;

    @media #{$mq-tablet} {
      display: flex;

      .vesselDisplay {
        margin-left: 10px;
      }
    }

    .fishingHours {
      cursor: pointer;
      margin-left: 7px;
      fill: $color-button-primary;
    }

    .counterDescription {
      color: $color-button-primary;
      font-family: $font-family-1;
      font-size: $font-size-xs-small;
      font-weight: $font-weight-bold;
      line-height: 1;
      text-transform: uppercase;
      margin-top: 4px;
    }

    .total {
      color: $color-4;
      font-family: $font-family-1;
      font-size: $font-size-x-small;
      font-weight: $font-weight-bold;
      margin-top: 4px;
    }

    > .categoriesDisplay {
      display: flex;

      > .vesselDisplay,
      > .activityDisplay {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .mapOptions {
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 15px;

    @media #{$mq-tablet} {
      flex-wrap: wrap;
      padding: 0;
    }

    > .layerManagement {
      display: flex;
      justify-content: space-between;
      margin: 10px 0 0;

      > .layerButton {
        border: 1px solid $color-3;
        color: $color-3;
        cursor: pointer;
        font-size: $font-size-xxs-small;
        font-weight: $font-weight-bold;
        outline: none;
        padding: 5px;
        text-transform: uppercase;
        width: calc(50% - 2px);
      }
    }
  }
}

.lightItem {
  background-color: $color-mid-blue;
  margin-bottom: 10px;
  margin-left: -35px;
  margin-right: -35px;
  color: $color-white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
}

.item {
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
  color: $color-white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}


.submenu {
  flex-direction: column;
  display: flex;
  height: 100%;
  padding: 10px 15px;

  .submenuHeader {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-bottom: 15px;
    padding-top: 24px;

    @media #{$mq-tablet} {
      flex-direction: row;
    }

    .submenuTitle {
      color: $color-4;
      cursor: default;
      font-family: $font-family-1;
      font-size: $font-size-xxs-small;
      font-weight: $font-weight-bold;
      margin: 5px 0 0;
      text-align: left;
      text-transform: uppercase;

      @media #{$mq-tablet} {
        font-size: $font-size-x-small;
        margin: 0;
        text-align: left;
      }
    }
  }

  .submenuContent {
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 108px);
    overflow-y: auto;
    padding: 15px 20px;

    @media #{$mq-tablet} {
      height: auto;
      margin: 10px 0 0;
      max-height: none;
      overflow-y: visible;
      padding: 15px 20px;
    }
  }
}

.icon {
  fill: $color-4;
  min-height: 18px;

  @media #{$mq-tablet} {
    min-height: none;
  }
}

.selectContainer {
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  :global .Select {
    flex: 1 1 auto;
  }

  &.fixedWidth {
    width: 256px;
    margin: 0 auto;
    margin-bottom: 20px;
    max-height: 50px;
  }
}

@import '../../../styles/settings';

.banner {
  display: flex;
  flex-direction: column;
  font-family: $font-family-1;
  font-size: $font-size-xs-small;
  font-weight: 400;
  min-height: 45px;
  justify-content: center;
  padding: 10px 10px 10px 50px;
  width: 100%;
  z-index: 999;

  @media #{$mq-tablet} {
    position: absolute;
    font-size: $font-size-small;
  }

  a {
    text-decoration: underline;
  }

  .closeButton {
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    height: 20px;
    left: 20px;
    position: absolute;
    top: 12px;
    width: 20px;

    > .icon {
      position: relative;
      top: -1px;
    }
  }
}

.notification {
  background-color: $color-19;
  border-bottom: 1px solid $color-1;
  color: $color-4;

  a {
    color: $color-4;
  }

  .closeButton {
    background-color: $color-4;
  }
}

.warning {
  background-color: $color-39;
  border-bottom: 1px solid $color-1;
  color: $color-1;

  .closeButton {
    background-color: $color-1;

    > .icon {
      color: $color-4;
    }
  }
}

.error {
  background-color: $color-6;
  border-bottom: 1px solid $color-1;
  color: $color-4;

  .closeButton {
    background-color: $color-4;

    > .icon {
      color: $color-6;
    }
  }
}

@import '../../settings';

.timebar {
  background-color: $color-1;
  display: flex;
  height: 100%;
  position: relative;
  z-index: 20;

  @media #{$mq-mobile-large} {
    padding-left: 5px;
  }
}

.timebarElement {
  height: 100%;
}

.timebarDatepicker {
  width: 15%;
  margin-left: 3px;
  border-left: 1px solid $color-fade-blue;

  @media #{$mq-mobile-large} {
    width: 20%;
    margin-left: 0;
    width: 98px;
    border-left: none;
  }
}

.timebarPlayback {
  text-align: center;
  margin-top: 12px;
  margin-left: 3px;
  margin-right: 3px;

  button {
    background: none;
    color: $color-3;
    cursor: pointer;
    border: 1px solid $color-3;
    border-radius: 50%;
    font-size: $font-size-x-small;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    height: 40px;
    width: 40px;

    @media #{$mq-mobile-large} {
      height: 40px;
      width: 40px;
    }

    &:focus {
      outline: none;
    }
  }
}

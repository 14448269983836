@import 'settings';

.wrap {
  margin: 0 auto;
  max-width: $max-width;
  padding: 0 $wrap-mobile;
  width: 100%;

  @media #{$mq-tablet} {
    padding: 0 $wrap-tablet;
  }

  @media #{$mq-laptop} {
    padding: 0 $wrap-desktop;
  }

  &._map {
    margin: 0;
    padding: 0 16px;
  }
}

@import '../../../../styles/settings';

.container {
  left: 8px;
  position: absolute;
  top: 144px;
  z-index: 6;

  @media #{$mq-tablet} {
    left: 6px;
    top: 110px;
  }
}

.mapLoader {
  z-index: 6;
  margin-top: 2px;

  &._isEmbedded {
    left: 6px;
    top: 110px;

    @media #{$mq-tablet} {
      top: 204px;
    }
  }
}

.latlon {
  display: none;

  @media #{$mq-tablet} {
    color: rgba($color-4, 0.7);
    display: block;
    font-size: $font-size-xxs-small;
    font-weight: bold;
    left: 56px;
    position: absolute;
    top: 0;
    z-index: 99;
  }
}

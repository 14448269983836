@import '../../settings';

:global ._hasBanner .header {
  @media #{$mq-tablet} {
    top: 50px;
  }
}

.header {
  background-color: $color-13;
  box-shadow: 0 2px 4px 0 rgba($color-12, 0.5);
  position: relative;
  z-index: 20;

  @media #{$mq-tablet} {
    background-color: transparent;
    box-shadow: none;
  }

  .containNav {
    align-items: center;
    display: flex;
    height: $header-height-mobile;
    justify-content: flex-end;

    @media #{$mq-tablet} {
      height: $header-height;
      justify-content: space-between;
    }
  }

  .iconMenuMobile {
    cursor: pointer;
    display: block;
    margin: 0 21px 0 0;
    width: 14px;

    @media #{$mq-mobile-large} {
      margin: 6px;
    }

    @media #{$mq-tablet} {
      display: none;
    }
  }

  .appLogo {
    display: flex;
    justify-content: center;
    margin-right: 21px;
    width: 100%;

    img {
      width: 210px;
      height: 80px;
    }

    @media #{$mq-tablet} {
      display: inline-block;
      margin-right: 0;
      width: auto;

      img {
        margin-top: 11px;
      }
    }
  }

  .iconShare {
    cursor: pointer;
    min-height: 16px;
    min-width: 19px;
    order: 3;

    @media #{$mq-tablet} {
      display: none;
    }
  }

  .shareHeader {
    color: $color-4;
    display: block;

    @media #{$mq-tablet} {
      display: none;
    }
  }

  .menu {
    display: none;

    @media #{$mq-tablet} {
      display: inline-block;
      list-style: none;
      position: relative;
      vertical-align: middle;
    }

    > li {
      display: inline-block;
      padding: 10px 12px;
      position: relative;

      @media #{$mq-laptop} {
        padding: 10px 30px;
      }

      &:last-of-type {
        padding-right: 0;

        &::before {
          background-color: $color-4;
          content: '';
          height: 25px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
        }
      }

      > a {
        color: $color-4;
        cursor: pointer;
        display: inline-block;
        font-weight: $font-weight-bold;
        height: 100%;
        letter-spacing: -0.1px;
        text-decoration: none;
        text-transform: capitalize;

        &._noCursor {
          cursor: default;
        }

        &._active {
          position: relative;

          &::after {
            background-color: $color-4;
            border-radius: 2px;
            bottom: -7px;
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            width: 100%;

            @media #{$mq-laptop} {
              left: -12px;
              width: calc(100% + 24px);
            }
          }
        }
      }

      > .dropdownContent {
        background-color: $color-4;
        display: none;
        left: 50%;
        list-style: none;
        padding: 12px;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: 200px;
        z-index: 20;

        a {
          color: $color-11;
          cursor: pointer;
          display: block;
          font-size: $font-size-small;
          padding: 12px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-color 0.3s;

          &:hover {
            background-color: rgba($color-3, 0.6);
          }
        }
      }

      &.dropdown {
        position: relative;

        &:hover > .dropdownContent {
          display: block;
        }
      }
    }
  }

  &._map {
    background-color: $color-19;
    box-shadow: none;
    left: 0;
    position: absolute;
    width: 100%;

    @media #{$mq-tablet} {
      background-color: transparent;
      width: auto;
    }

    .iconMenuMobile {
      order: 1;

      @media #{$mq-tablet} {
        display: block;
      }
    }

    .appLogo {
      margin-left: 15px;
      order: 2;

      @media #{$mq-tablet} {
        order: 1;
      }

      &._isEmbedded {
        margin: 0;
      }
    }

    .shareHeader {
      order: 3;

      @media #{$mq-tablet} {
        display: none;
      }
    }

    .menu {
      @media #{$mq-tablet} {
        display: none;
      }
    }
  }

  .loginLink {
    max-width: 80px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 4px;
    white-space: nowrap;
  }
}

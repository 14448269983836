@import '../settings';

.mobileMenu {
  -webkit-overflow-scrolling: touch;
  background-color: $color-11;
  bottom: 0;
  box-shadow: 0 2px 4px 0 rgba($color-12, 0.5);
  left: 0;
  overflow: auto;
  padding: 25px 30px;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform ease-in 0.2s;
  width: 300px;
  z-index: 104;

  @media #{$mq-tablet} {
    width: 325px;
  }

  &._show {
    transform: translateX(0);
  }

  ul {
    list-style: none;

    li {
      color: $color-4;
      display: block;
      font-size: $font-size-x-normal;
      font-weight: $font-weight-bold;
      line-height: 2.58;

      a {
        //Color for Android and IOS
        color: $color-4;
      }
    }
  }

  .submenuMobile {
    list-style: none;
    padding-left: 30px;

    li {
      color: $color-4;
      display: block;
      font-size: $font-size-x-normal;
      line-height: 2.58;

      > a {
        color: $color-4;
        font-weight: $font-weight-light;
      }
    }
  }

  .buttonLogin {
    background-color: $color-3;
    border: 0;
    color: $color-4;
    cursor: pointer;
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    line-height: 1.29;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 22px 60px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .forgotText {
    color: $color-4;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    padding-left: 0;

    .forgotPassword {
      color: $color-4;
    }

    > .register {
      background: none;
      border: 0;
      cursor: pointer;

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
      }
    }
  }
}

.menuBack {
  background-color: rgba($color-12, 0.8);
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 103;

  &._show {
    display: block;
    opacity: 0.9;
  }
}

@import '../settings';
@import '../mixins';

.mapPanels {
  z-index: 30;

  @media #{$mq-tablet} {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$timebar-height + $map-footer-height});
    justify-content: space-between;
    pointer-events: none;
    position: absolute;
    right: - $map-panels-width - 10px;
    width: $map-panels-width + $control-panel-left-offset;
    transition: right 0.2s;

    &._noFooter {
      height: calc(100vh - #{$timebar-height});
    }

    &._expanded {
      right: 0;

      .expandButton {
        left: - (30px - $control-panel-left-offset);
      }

      .expandButton::after {
        margin-left: 8px;
        transform: rotate(225deg);
      }
    }
  }
}

.expandButton {
  display: none;
  position: absolute;
  left: - (30px - $control-panel-left-offset) - 10px;
  transition: left 0.2s;
  top: 0;
  height: 73px;
  width: 30px;
  background: $color-19;
  color: $color-white;
  pointer-events: all;
  cursor: pointer;
  z-index: 99;

  @media #{$mq-tablet} {
    display: block;
  }

  &::after {
    margin-top: 30px;
    margin-left: 12px;

    @include arrow(10px, 2px, $color-white, 'left');
  }
}

// Font families
$font-family-1: 'Roboto', sans-serif;
$font-family-2: 'Roboto Slab', serif;

// Layout
$max-width: 1280px;
$wrap-mobile: 25px;
$wrap-tablet: 55px;
$wrap-desktop: 115px;
$map-footer-height: 38px;
$max-map-footer-height: 485px;
$offset-panel-control-mobile: 108px;
$offset-panel-control: 20px;

$menu-height: 73px;
$menu-mobile-height: 62px;
$menu-left-padding: 25px;
$menu-right-padding: 25px;
$menu-mobile-left-padding: 25px;
$menu-mobile-right-padding: 25px;

$header-height-mobile: 80px;
$header-height: 66px;
$mobile-height: 60px;
$control-panel-height: 70vh;
$control-panel-mobile-height: 40vh;
$vessel-info-height: 70px;
$vessel-info-height-encounters: 36px;

$submenu-header-height: 76px;

$map-panels-width: 320px;
$control-panel-left-offset: 14px;


// Map
$timebar-height: 70px;
$duration-picker-height: 24px;
$report-panel-menu-height: 48px;

// Font sizes
$font-size-tiny: 10px;
$font-size-xxs-small: 12px; // UI Kit map
$font-size-xs-small: 13px; // UI Kit map
$font-size-small: 14px; // UI kit
$font-size-x-small: 15px; // UI kit
$font-size-normal: 16px;
$font-size-x-normal: 18px;
$font-size-medium: 20px;
$font-size-s-medium: 22px;
$font-size-x-medium: 25px; // UI kit
$font-size-big: 30px;
$font-size-x-big: 34px;
$font-size-xs-big: 35px;
$font-size-xx-big: 40px; // UI kit
$font-size-xxx-big: 70px; // UI kit
$font-size-huge: 100px;

// Font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-light-bold: 500;
$font-weight-bold: 700;

// Colors
$color-1: #011137; // UI kit map
$color-2: #021236;
$color-3: #8abbc7;
$color-4: #fff;
$color-5: #b8b8b8;
$color-6: #ef4323;
$color-7: #294480;
$color-8: #18306d;
$color-9: #4a4a4a; // UI kit
$color-11: #0d1a34;
$color-12: #000;
$color-13: #2b4f93;
$color-19: #174084; // UI kit map
$color-22: #073338; // UI kit map
$color-24: rgba(3, 32, 68, 0); // used for gradient at homepage
$color-28: #39579b;
$color-29: #da4735;
$color-30: #55acee;
$color-31: #46a4b9;
$color-32: #081c4f;
$color-33: #000f35;
$color-34: #99d3e1;
$color-35: #153b78;
$color-36: #0a1e50;
$color-37: #ffbcc6;
$color-38: rgba(22, 48, 103, 0.2);
$color-39: #ffcc6e;

$hsla-begin: hsla(0, 0%, 0%, 0.1);
$hsla-end: hsla(0, 0%, 100%, 0.1);

$color-base: $color-1;
$color-secondary: $color-13;
$color-highlight: $color-3;
$color-button-primary: $color-31;
$color-mid-blue: $color-8;
$color-white: $color-4;
$color-fade-blue: rgba($color-31, 0.3);
$color-fade-grey: rgba($color-5, 0.3);
$color-fishing-hours-timebar: $color-36;
$color-red: red;
$color-salmon: $color-37;

// Layer colors

$yellow: #fbff8b;
$light-blue: #67fbfe;
$green: #abff35;
$brightGreen: #00ff6a;
$blue: #7d84fa;
$purple: #b0f;
$peach: #fca26f;
$orange: #f95e5e;
$pink: #ff81f5;

// Colors to review
$color-14: #2d3a57;
$color-15: #0d1d3f;
$color-16: rgba(255, 255, 255, 0.9);
$color-17: #111;
$color-18: rgba(255, 255, 255, 0.9);


// Breakpoints
$breakpoint-mobile-small: 320px;
$breakpoint-mobile-large: 425px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1280px;

// Media queries
$mq-mobile-small: 'screen and (min-width: #{$breakpoint-mobile-small})';
$mq-mobile-large: 'screen and (min-width: #{$breakpoint-mobile-large})';
$mq-tablet: 'screen and (min-width: #{$breakpoint-tablet})';
$mq-laptop: 'screen and (min-width: #{$breakpoint-laptop})';
$mq-desktop: 'screen and (min-width: #{$breakpoint-desktop})';

// Animation
$panel-animation-delay: 300ms;
$panel-animation-ease: ease-in;

@import '../../settings';

.title {
  color: $color-4;
  font-size: $font-size-x-normal;
  font-weight: $font-weight-bold;
  text-align: center;
}

.message {
  color: rgba($color-34, 0.3);
}

.optionsContainer {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section,
._bottomPadding {
  padding-bottom: 40px;
}

.foldableAction {
  cursor: pointer;

  &::after {
    content: '▾';
    margin-left: 10px;
    transform: rotateZ(180deg);
  }

  &._closed {
    &::after {
      transform: rotateZ(0deg);
    }
  }
}

.foldable {
  height: auto;
  max-height: 200px;
  overflow: hidden;
  transition: all 0.4s;

  &._closed {
    height: 0;
    max-height: 0;
    padding: 0;
  }
}

.sectionTitle {
  margin-bottom: 20px;
}

.itemTitle {
  color: $color-4;
  font-size: $font-size-small;
  margin-bottom: 5px;
  margin-left: 15px;
  text-transform: uppercase;
}

.nameInput {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $color-fade-blue;
  padding-bottom: 5px;
  border-radius: 0;
  color: $color-white;
  font-size: $font-size-x-small;
  font-weight: $font-weight-normal;
  line-height: 1.8;
  outline: 0;
  padding-right: 30px;
  width: 100%;

  @media #{$mq-tablet} {
    margin-bottom: 20px;
  }

  &::-webkit-input-placeholder {
    color: $color-fade-blue;
  }

  &::-moz-placeholder {
    color: $color-fade-blue;
  }

  &:-ms-input-placeholder {
    color: $color-fade-blue;
  }
}


.wrapper {
  padding-bottom: 40px;
}

.warning {
  margin: 40% 0 40px;
}

@import '../../settings';
@import '../../mixins';

.expandButton {
  background-color: $color-4;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  outline: none;
  position: relative;

  &::after {
    @include arrow(8px, 2px, $color-19, 'down');

    left: calc(50% - 4px);
    position: absolute;
    top: calc(50% + 1px);
    transform: rotate(45deg) translate(-50%, -50%);
  }

  &._expanded {
    &::after {
      @include arrow(8px, 2px, $color-19, 'up');

      left: calc(50% - 4px);
      top: calc(50% + 3px);
      transform: rotate(45deg) translate(-50%, -50%);
    }
  }
}

@import '../../settings';

.fieldName {
  color: $color-4;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  & + * {
    margin: 5px 0 0;
  }
}

.textInput,
.textarea {
  background: transparent;
  border: 1px solid $color-14;
  border-radius: 0;
  color: $color-4;
  font-family: $font-family-1;
  font-size: $font-size-small;
  padding: 10px;

  ::-webkit-input-placeholder {
    color: rgba($color-14, 0.3);
  }

  ::-moz-placeholder {
    color: rgba($color-14, 0.3);
  }

  :-ms-input-placeholder {
    color: rgba($color-14, 0.3);
  }
}

.textarea {
  height: 100%;
  min-height: 124px;
  resize: none;

  @media #{$mq-tablet} {
    height: auto;
    min-height: 85px;
  }
}

.radioGroup {
  display: flex;
  margin-top: 18px;
  margin-left: 2px;
}

.radioGroup label {
  margin-left: 8px;
}

.help {
  margin-top: 4px;
  opacity: 0.6;
  font-size: $font-size-small;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

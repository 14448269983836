@import '../../../styles/settings';
@import '../../../styles/mixins';

.expandableIconButton {
  &._activated::after {
    top: 34px;
    left: 10px;
    display: inline-block;
    position: absolute;
    content: "";

    @include triangle($color-mid-blue, 'up');
  }
}

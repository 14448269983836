@import '../../settings';

.paginator {
  align-items: center;
  display: flex;

  > .pageList {
    display: flex;
    margin: 0 50px;

    > .previous,
    > .next {
      cursor: pointer;

      &._disabled {
        fill: rgba($color-3, 0.3);
        pointer-events: none;
      }
    }

    > .previous {
      margin: 0 50px 0 0;
      transform: rotateY(180deg);
    }

    > .next {
      margin: 0 0 0 50px;
    }

    > .pageItem {
      color: $color-4;
      font-size: $font-size-x-small;
      font-weight: $font-weight-bold;
      margin: 0 0 0 25px;

      > a {
        cursor: pointer;
      }

      &:nth-child(2) {
        margin: 0;
      }

      &._current {
        color: $color-3;
        position: relative;

        &::before {
          background: $color-3;
          border-radius: 100px;
          bottom: 4px;
          content: '';
          display: inline-block;
          height: 4px;
          left: 50%;
          min-width: 16px;
          position: absolute;
          transform: translate(-50%, 0);
          width: 100%;
        }
      }
    }
  }
}

@import '../settings';

.button {
  background-color: $color-32;
  border: 1px solid $color-31;
  color: $color-4;
  cursor: pointer;
  font-family: $font-family-1;
  font-size: $font-size-xs-small;
  font-weight: $font-weight-light-bold;
  height: 30px;
  outline: none;
  text-align: center;
  text-transform: uppercase;

  &._disabled {
    border: 1px solid rgba($color-3, 0.25);
    color: rgba($color-3, 0.5);
    pointer-events: none;
  }

  &._filled {
    background-color: $color-button-primary;
    border: 0;
    color: $color-4;
  }

  &._filled:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &._transparent {
    background-color: transparent;
  }

  &._noBorderRadius {
    border-radius: 0;
  }

  &._disabled._filled {
    background-color: rgba($color-button-primary, 0.3);
  }

  &._big {
    height: 40px;
  }

  &._wide {
    width: 100%;
  }

  &._half {
    width: 50%;
  }

  &._top {
    vertical-align: top;
  }
}

.buttonSubmitSmall {
  background-color: $color-3;
  border: 0;
  color: $color-4;
  cursor: pointer;
  display: block;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  letter-spacing: -0.1px;
  margin: 45px auto 0;
  max-width: 115px;
  outline: 0;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.deleteButton {
  width: 27px;
  height: 27px;
  padding-top: 2px;
}

.infoButton {
  margin-left: 5px;
}

.speedButton {
  outline: none;

  &.decrease {
    margin-left: 5px;
    transform: rotate(180deg);

    @media #{$mq-tablet} {
      margin-left: 0;
    }
  }
}

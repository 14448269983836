@import '../../../../styles/settings';

.zoomControls {
  display: none;

  @media #{$mq-tablet} {
    display: block;
    z-index: 6;
  }

  > .control {
    align-items: center;
    background-color: $color-19;
    box-shadow: 0 0 8px 0 rgba($color-22, 0.3);
    color: $color-4;
    cursor: pointer;
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    text-align: center;

    &:not(:first-child) {
      margin: 2px 0 0;
    }

    &._disabled {
      opacity: 0.75;
      pointer-events: none;
    }
  }
}

@import '../../settings';

.welcomeModal {
  display: flex;
  flex-direction: column;
  font-family: $font-family-1;
  position: relative;

  @media #{$mq-tablet} {
    max-height: 80vh;
  }
}

.content {
  overflow: auto;

  @media #{$mq-tablet} {
    max-height: 60vh;
  }

  @media #{$mq-desktop} {
    max-height: 80vh;
  }

  h1,
  h2 {
    font-weight: $font-weight-bold;
    margin: 24px 0 12px;
    text-align: left;

    &:first-child {
      margin: 0 0 12px;
    }
  }

  h1 {
    font-size: $font-size-medium;
  }

  h2 {
    font-size: $font-size-normal;
  }

  p,
  ul {
    font-size: $font-size-x-small;
    margin: 12px 0;

    &:first-child {
      margin: 0;
    }
  }

  li {
    margin: 12px 0 0 1em;
  }

  ul {
    list-style: square;
  }

  a {
    border-bottom: solid 1px $color-29;
    color: $color-4;
    display: inline-block;
    font-family: $font-family-1;
    font-size: $font-size-x-small;
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
  }

  @media #{$mq-desktop} {
    h1 {
      font-size: $font-size-xx-big;
    }

    h2 {
      font-size: $font-size-big;
    }

    p,
    ul,
    li,
    a {
      font-size: $font-size-medium;
    }
  }
}

@import '../settings';

.menuLink {
  align-items: center;
  color: $color-5;
  display: inline-flex;

  &:last-child {
    border-bottom: 0;
  }

  @media #{$mq-mobile-large} {
    border-bottom: 1px solid rgba($color-34, 0.2);
    display: block;
    width: 100%;
  }

  .header {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10px 15px;

    @media #{$mq-mobile-large} {
      padding: 20px 25px;
      flex-direction: row;

      &:hover {
        background-color: rgba($color-3, 0.2);
      }
    }
  }

  .iconContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    @media #{$mq-mobile-large} {
      flex-direction: row;
    }

    &.hasBadge {
      padding-left: 14px;

      @media #{$mq-mobile-large} {
        margin-left: 0;
      }
    }

    .badge {
      color: $color-4;
      font-weight: bold;
      border: 1px solid $color-fade-grey;
      border-radius: 50%;
      font-size: $font-size-tiny;
      padding: 1px 4px;
      margin-top: -15px;
      margin-right: 0;
      margin-left: 3px;

      @media #{$mq-mobile-large} {
        margin-right: 14px;
        font-size: $font-size-xs-small;
        margin-top: -2px;
        margin-left: 0;
        padding: 3px 7px;
      }
    }
  }


  .title {
    color: $color-4;
    font-family: $font-family-1;
    font-size: $font-size-xxs-small;
    font-weight: $font-weight-bold;
    margin: 5px 0 0;
    text-align: center;
    text-transform: uppercase;

    @media #{$mq-mobile-large} {
      font-size: $font-size-x-small;
      margin: 0;
      text-align: left;
    }
  }
}

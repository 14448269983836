@import '../../settings';

.title {
  color: $color-4;
  padding-bottom: 18px;
  font-size: $font-size-small;
}

.layerListContainer {
  .layerList {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @media #{mq-tablet} {
      max-height: 0;
    }
  }
}

.itemNameLabel {
  color: $color-4;
  position: absolute;
  left: 25px;
  font-family: $font-family-1;
  font-size: $font-size-normal;

  @media #{$mq-tablet} {
    line-height: 20px;
    font-size: $font-size-xxs-small;
  }
}

.itemNameLabelEdit {
  pointer-events: none;
}

.itemNameInput {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: $color-4;
  flex-grow: 2;
  font-family: $font-family-1;
  font-size: $font-size-normal;
  height: 24px;
  margin-top: 1px;
  outline: none;
  text-overflow: ellipsis;
  opacity: 0;

  @media #{$mq-tablet} {
    line-height: 20px;
    font-size: $font-size-xxs-small;
  }
}

.itemRename {
  input {
    border-bottom-color: rgba($color-3, 0.3);
    opacity: 1;
  }

  label {
    opacity: 0;
  }
}

.editionMenu {
  display: flex;
  justify-content: flex-end;
}

.itemOptionList {
  align-items: center;
  display: flex;
  padding-left: 10px;
  margin-left: auto;
}

.itemOptionItem {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  margin-right: 5px;

  .warningIcon {
    height: 14px;
    width: 12px;
    margin-left: -2px;

    > path {
      fill: $color-button-primary;
    }
  }
}

.layerItemHeader {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
}

.disabled {
  cursor: not-allowed;
}

@import 'settings';

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke: currentColor;
  stroke-width: 0;
  width: 1em;
  display: block;
}

:global .iconSearch {
  width: 0.9375em;
}

:global .iconFilter {
  width: 1.154296875em;
}

.iconLayersIcon {
  width: 0.89453125em;
}

.deleteIcon {
  cursor: pointer;
  fill: $color-button-primary;
  height: 16px;
  width: 12px;
}

.aoiIcon {
  width: 15px;
  height: 15px;
}

.playIcon {
  height: 100%;
  width: 13px;
  position: relative;
  left: 2px;
}

.pauseIcon {
  height: 100%;
  width: 13px;
}

.iconShare {
  fill: $color-white;
  height: 12px;
  width: 14px;
}

.iconZoomIn {
  fill: $color-white;
  height: 14px;
  width: 14px;
}

.iconZoomOut {
  fill: $color-white;
  height: 4px;
  width: 14px;
}

.iconClose {
  cursor: pointer;
  height: 10px;
  width: 10px;
}

.iconArrowLink {
  cursor: pointer;
  fill: $color-button-primary;
  height: 15px;
  width: 15px;
}

.infoIcon {
  cursor: pointer;
  fill: $color-button-primary;
  height: 17px;
  width: 10px;
  margin-top: -2px;

  &.small {
    height: 13px;
    width: 5px;
  }
}

.selectIcon {
  cursor: pointer;
  fill: $color-white;
  width: 22px;
  height: 30px;
}

.paintIcon {
  cursor: pointer;
  fill: $color-button-primary;
  height: 14px;
  width: 14px;
  position: relative;
  top: 1px;

  &._white {
    fill: $color-white;
  }
}

.deleteCrossIcon {
  cursor: pointer;
  fill: $color-19;
  height: 15px;
  width: 15px;
}

.speedIcon {
  cursor: pointer;
  fill: $color-highlight;
  outline: none;
  height: 10px;
  width: 10px;
}

.pencilIcon {
  cursor: pointer;
  fill: $color-button-primary;
  height: 15px;
  width: 15px;
}

.deleteBucketIcon {
  cursor: pointer;
  fill: $color-button-primary;
  height: 15px;
  width: 15px;
}

.pinIcon {
  cursor: pointer;
  height: 16px;
  width: 11px;
}

.searchIcon {
  fill: $color-button-primary;
  height: 15px;
  width: 15px;
}

@import '../../settings';

.layerManagementModal {
  position: relative;

  &._disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &Loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 104;
  }
}

.title {
  color: $color-4;
  font-size: $font-size-x-normal;
  font-weight: $font-weight-bold;
  text-align: center;
}

.contentSwitcher {
  align-items: center;
  border: 1px solid $color-14;
  border-radius: 100px;
  display: flex;
  font-weight: $font-weight-bold;
  height: 40px;
  justify-content: space-around;
  margin: 25px auto;
  max-width: 304px;
}


.contentOption {
  color: $color-4;
  cursor: pointer;
  font-size: $font-size-xs-small;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: calc(50% - 9px);
  z-index: 1;

  &._selected {
    color: $color-1;

    &::before {
      background-color: $color-4;
      border-radius: 100px;
      content: '';
      display: inline-block;
      height: 30px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: -1;
    }
  }
}

@import '../settings';
@import '../mixins';

.searchPanel {
  z-index: 11;
  padding: 15px 25px;

  @media #{$mq-tablet} {
    padding-right: $menu-right-padding;
    padding-left: $menu-left-padding;
    position: relative;
  }

  :global .iconSearch,
  :global .iconClose {
    position: absolute;
    right: 0;
  }

  :global .iconSearch {
    top: 8px;
  }

  :global .iconFilter {
    cursor: pointer;
  }
}

.searchInput {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba($color-3, 0.3);
  border-radius: 0;
  color: $color-4;
  display: block;
  font-size: $font-size-x-small;
  font-weight: $font-weight-bold;
  font-family: $font-family-1;
  line-height: 1.8;
  outline: 0;
  padding-right: 30px;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $color-button-primary;
    font-weight: $font-weight-normal;
  }

  &::-moz-placeholder {
    color: $color-button-primary;
    font-weight: $font-weight-normal;
  }

  &:-ms-input-placeholder {
    color: $color-button-primary;
    font-weight: $font-weight-normal;
  }
}

.cleanQueryButton {
  position: absolute;
  right: 55px;
  stroke: rgba($color-3, 0.3);
  top: 84px;

  @media #{$mq-tablet} {
    top: 24px;
  }
}

.searchIcon {
  position: absolute;
  right: 32px;
  top: 82px;

  @media #{$mq-tablet} {
    top: 22px;
  }
}

.resultsContainer {
  background-color: $color-32;
  max-height: 0;
  overflow: hidden;

  &._open {
    border: 1px solid rgba($color-3, 0.3);
    left: 0;
    max-height: none;
    overflow: visible;
    padding: 14px 9px 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 123px;

    @media #{$mq-mobile-large} {
      flex-direction: row;
    }

    @media #{$mq-tablet} {
      position: relative;
      top: -1px;
      margin-left: -25px;
      width: 320px;
    }
  }
}

.searchList {
  padding: 0 0 15px;

  > .result {
    width: 100%;
  }
}

.paginationContainer {
  display: flex;
  justify-content: center;
  padding: 5px 0 24px;

  .paginationButton {
    margin-left: 0;
    margin-right: 0;
    width: 64%;

    @media #{$mq-tablet} {
      width: 84%;
      margin-left: -6px;
      margin-right: 2px;
    }
  }
}

@import '../../settings';

.list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media #{mq-tablet} {
    font-size: $font-size-small;
  }

  &._separated {
    margin-top: 18px;
  }
}

.title {
  color: $color-4;
  padding-bottom: 18px;
  font-size: $font-size-small;
}

.spacedTitle {
  padding-top: 37px;
}

.layerListContainer {
  .layerList {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @media #{mq-tablet} {
      max-height: 0;
    }
  }
}

.listItemContainer {
  width: 100%;
}

.listItem {
  align-items: center;
  display: flex;
  padding-left: $menu-mobile-left-padding;
  padding-right: $menu-mobile-right-padding;
  position: relative;
  width: 100%;
  margin: 0 0 5px;

  @media #{$mq-tablet} {
    padding-left: $menu-left-padding;
    padding-right: $menu-right-padding;

    &._alignLeft {
      padding-right: 4px;
    }
  }

  &._noPadding {
    padding: 0;
  }

  &._noMobilePadding {
    padding: 0;
  }

  &._noMobileRightPadding {
    padding-right: 0;

    @media #{$mq-tablet} {
      padding-right: 32px;
    }
  }

  &._fixed {
    height: 40px;
  }

  &.halfRow {
    display: inline-block;
    width: 50%;
    margin: 0;

    &:first-child > .layerInfo {
      margin: 0;
    }


    .layerThumbnail {
      border: 1px solid $color-19;
      height: 30px;
      margin: 0 10px 0 0;
      width: 30px;
    }
  }

  &:first-child > .itemInfo {
    margin: 0 0 5px;
  }

  &:not(:first-child) > .itemInfo {
    margin: 5px 0;
  }

  &._selected {
    > .itemInfo > .layerThumbnail {
      border: 2px solid $color-4;
    }
  }

  &._baseline {
    align-items: baseline;
  }
}

.itemInfo {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  max-width: 90%;

  @media #{$mq-tablet} {
    max-width: 195px;
  }
}

.itemThumbnail {
  border: 1px solid $color-19;
  height: 30px;
  margin: 0 10px 0 0;
  width: 30px;
}

.itemTitle {
  color: $color-4;
  flex-grow: 2;
  font-family: $font-family-1;
  font-weight: $font-weight-normal;
  line-height: 2;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media #{$mq-tablet} {
    font-size: $font-size-xxs-small;
  }
}

.renameIcon {
  position: absolute;
  right: 30px;
  stroke: rgba($color-3, 0.3);
  top: 50%;
  transform: translate(0, -50%);
}

.editionMenu {
  display: flex;
  justify-content: flex-end;
}

.itemOptionList {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 10px;

  ._highlighted {
    path {
      &:first-child {
        fill: $color-4;
      }

      &:last-child {
        fill: $color-31;
      }
    }
  }

  .itemOptionItem {
    margin-bottom: -10px;
  }

  .itemOptionItem:not(:last-child) {
    margin-right: 15px;
  }

  &._inlineList {
    justify-content: flex-start;
    margin-left: 5px;
  }
}

.infoIcon {
  margin-left: 5px;
  min-width: 20px;
}

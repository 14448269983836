@import '../../settings';

.flagFilters {
  padding: 20px 25px;

  @media #{$mq-tablet} {
    max-height: none;
    overflow-y: visible;
  }
}

.filtersList {
  margin-bottom: 14px;
}

.filterItem {
  align-items: center;
  display: flex;
  position: relative;
  margin: 0 0 10px;
}

.filterOption {
  flex: 1;

  @media #{mq-tablet} {
    width: 20%;
  }
}

.filterOptionList {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 0 10px;
}

.filterOptionItem {
  display: inline-flex;
  position: relative;

  &:not(:first-child) {
    margin: 0 0 0 15px;
  }
}

.filterButton {
  height: 40px;
  margin: 10px 0;
  max-width: 92.5%;
  width: 100%;

  @media #{$mq-tablet} {
    height: 30px;
    max-width: none;
    width: 248px;
  }
}

.noFiltersMessage {
  color: $color-3;
  font-size: $font-size-xxs-small;
  font-style: italic;
  margin-bottom: 10px;
}

@import '../../settings';

.popup {
  background: $color-13;
  bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  left: -130px;
  opacity: 0.95;
  padding: 16px;
  width: 260px;
  color: $color-4;
  font-family: $font-family-1;
  font-size: $font-size-xs-small;

  &._compact {
    padding: 6px;
  }
}

.title {
  margin-bottom: 10px;
}

.title,
.toggle {
  font-weight: bold;
  text-transform: uppercase;
}

.toggle:focus {
  outline: none;
}

.description a {
  color: $color-4;
  text-decoration: underline;
}

.toggle {
  cursor: pointer;
  margin-top: 14px;
  color: $color-4;

  &::before {
    background: $color-4;
    color: $color-13;
    content: '+';
    display: inline-block;
    margin-right: 8px;
    width: 20px;
    height: 16px;
  }

  &._remove {
    &::before {
      content: '-';
    }
  }
}

@import '../../settings';

.timeline {
  position: relative;
  width: 50%;

  > svg {
    display: block;
  }

  @media #{$mq-mobile-large} {
    flex: 1;
  }
}

.timelineArea {
  fill: $color-8;
}

.timelineXAxis {
  line,
  path {
    stroke: $color-4;
  }

  text {
    display: none;

    @media #{$mq-mobile-large} {
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
      display: block;
      fill: $color-4;
      font-family: $font-family-1;
      font-size: $font-size-xxs-small;
      user-select: none;
    }

    @media #{$mq-laptop} {
      font-size: $font-size-xs-small;
    }
  }
}

.timelineLabel {
  transform: translate(7px, 15px);
  pointer-events: none;

  text {
    display: none;

    @media #{$mq-mobile-large} {
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
      display: block;
      fill: $color-4;
      font-family: $font-family-1;
      font-size: $font-size-xxs-small;
      user-select: none;
    }

    @media #{$mq-laptop} {
      font-size: $font-size-xs-small;
    }
  }
}

.timelineFullYear {
  font-weight: bold;
}

.timelineOuterBrushSelection {
  fill-opacity: 0;
  stroke-width: 0;
}

.timelineOuterBrushHandle {
  display: none;

  @media #{$mq-mobile-large} {
    cursor: ew-resize;
    display: block;

    path,
    rect {
      stroke: $color-3;
    }

    path {
      stroke-width: 4px;
    }

    rect {
      fill: $color-8;
      height: 6px;
      stroke-width: 3px;
      transform: translate(-3px, -3px);
      width: 6px;
    }
  }
}

.timelineInnerBrush {
  :global .handle {
    display: none;

    @media #{$mq-mobile-large} {
      display: block;
    }
  }
}

.timelineInnerBrushSelection {
  fill: $color-fishing-hours-timebar;
  fill-opacity: 0.5;
  height: $timebar-height;
  stroke: $color-mid-blue;
  stroke-width: 1px;
}

.timelineInnerBrushCircle {
  cursor: ew-resize;
  fill: $color-4;
  pointer-events: none;
}

.timelineInnerBrushCircles {
  display: none;

  @media #{$mq-mobile-large} {
    display: block;
  }
}

.timelineInnerBrushMiddle {
  pointer-events: none;

  > path {
    stroke: $color-3;
  }

  @media #{$mq-mobile-large} {
    display: none;
  }
}

@import '../../settings';

.checkbox {
  &.-spaced {
    margin-bottom: 8px;
  }

  [type="checkbox"]:disabled,
  [type="checkbox"]:disabled + label {
    opacity: 0.4;
    cursor: not-allowed;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }

  [type="checkbox"]:not(:checked) + label::before,
  [type="checkbox"]:checked + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid rgba($color-34, 0.3);
    background: $color-button-primary;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label::after,
  [type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    top: 3px;
    left: 3px;
    font-size: 1.4em;
    line-height: 0.8;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    transition: all 0.2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label::after {
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  [type="checkbox"]:checked + label::after {
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }
}

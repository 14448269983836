@import '../../settings';
@import '../../mixins';

.blending {
  display: flex;
}

:global .blendingRange {
  appearance: none;
  background: transparent;
  height: 25px;
  position: relative;
  top: 5px;
  width: 100%;

  :global .trackContainer {
    background-color: transparent;
    cursor: pointer;
    height: 15px;
    position: relative;

    .trackActive {
      background-color: $color-4;
      height: 3px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    &::before {
      background: $color-13;
      content: '';
      display: inline-block;
      height: 3px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
    }
  }

  :global .thumbContainer {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  :global .thumb {
    background-color: $color-2;
    border: 2px solid $color-4;
    display: inline-block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
  }

  :global .label {
    display: none;
  }
}

:global .blendingRange._hue {
  height: 25px;

  :global .trackContainer {
    .trackActive {
      display: none;
    }

    &::before {
      background: linear-gradient(to right, rgb(204, 41, 41), rgb(204, 139, 41), rgb(171, 204, 41), rgb(73, 204, 41), rgb(41, 204, 106), rgb(41, 204, 204), rgb(41, 106, 204), rgb(73, 41, 204), rgb(171, 41, 204), rgb(204, 41, 139), rgb(204, 41, 41));
    }
  }

  :global .label {
    display: none;
  }
}

@import '../../settings';
@import '../../mixins';

// this component needs crossbrowsing

.toggle {
  appearance: none;
  background: $color-7;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  height: 10px;
  margin: 0 5px 0 0;
  min-width: 20px;
  outline: 0;
  padding: 0 10px 0 0;
  position: relative;

  &._active {
    background: $color-3;

    .toggleBall {
      left: 10px;
    }
  }

  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .toggleBall {
    background: $color-4;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 0.15s ease-in;
    width: 10px;

    // Can't be a pseudo-element because we need js manipulation
    .toggleInnerBall {
      background-color: $color-4;
      border-radius: 50%;
      height: 4px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4px;
    }
  }
}

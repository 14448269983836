@mixin arrow($size, $border-width, $color, $direction) {
  border: $border-width solid $color;
  content: '';
  display: inline-block;
  height: $size;
  width: $size;

  @if ($direction == 'up') {
    border-bottom: 0;
    border-right: 0;
    transform: rotate(45deg);
  }

  @if ($direction == 'right') {
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
  }

  @if ($direction == 'down') {
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
  }

  @if ($direction == 'left') {
    border-right: 0;
    border-top: 0;
    transform: rotate(45deg);
  }
}

@mixin triangle($color, $direction) {
  width: 0;
  height: 0;

  @if ($direction == 'up') {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $color;
  }

  @if ($direction == 'down') {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color;
  }
}

@mixin line($color) {
  width: 8px;
  height: 3px;
  vertical-align: middle;
  background-color: $color;
}

@mixin panelRow() {
  margin: 10px 0 0;
  padding-right: 26px;
  padding-left: $menu-mobile-left-padding;

  @media #{$mq-tablet} {
    padding-left: $menu-left-padding;
    padding-right: $menu-right-padding;
  }
}

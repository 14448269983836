@import '../../settings';

.searchContainer {
  position: relative;
}

.searchInput {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $color-14;
  color: $color-4;
  font-size: $font-size-x-small;
  line-height: 1.9;
  outline: none;
  padding: 0 20px 0 0;
  width: 100%;

  ::-webkit-input-placeholder {
    color: $color-14;
  }

  ::-moz-placeholder {
    color: $color-14;
  }

  :-ms-input-placeholder {
    color: $color-14;
  }
}

> .searchIcon {
  bottom: 4px;
  fill: $color-14;
  position: absolute;
  right: 0;
}

.wrapper {
  overflow: auto;
  padding: 0 0 5px;
  position: relative;

  &::after {
    background-image: linear-gradient(to bottom, rgba(4, 20, 56, 0), $color-2);
    bottom: 25px;
    content: '';
    display: inline-block;
    height: 25px;
    left: 0;
    margin-top: 10px;
    pointer-events: none;
    position: relative;
    width: 100%;
  }
}

.list {
  background-image: linear-gradient(to bottom, $color-24, $color-2);
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
  max-height: 225px;
  overflow-y: auto;
  padding: 0 0 12px;

  @media #{$mq-tablet} {
    justify-content: space-between;
  }
}

.listItem {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  max-width: 100%;

  &:not(:first-child) {
    margin: 10px 0 0;
  }

  @media #{$mq-tablet} {
    flex-basis: 40%;
    max-width: 40%;

    &:first-child,
    &:nth-child(2) {
      margin: 0;
    }
  }

  label {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    max-width: 90%;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.footerContainer {
  display: flex;
  justify-content: center;
  margin: 10px 0 0;

  > .mainButton {
    width: 180px;
  }
}

@import '../settings';

.resultList {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  @media #{$mq-tablet} {
    max-height: none;
  }
}

.modalResult {
  color: rgba($color-4, 0.7);
  padding: 14px 28px;
  align-items: center;
  flex: 1;
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
  overflow-y: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 650px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.quickSearchResult {
  color: rgba($color-4, 0.7);
  padding: 14px 11px;
}

.resultItem {
  border-radius: 4px;
  cursor: pointer;
  font-size: $font-size-xs-small;
  font-family: $font-family-1;
  display: block;

  > span {
    display: block;
    width: 100%;
  }

  span:not(:first-of-type) {
    margin: 5px 0 0;
  }

  &:hover {
    background-color: rgba($color-35, 0.5);
  }

  .highlight {
    color: $color-4;
    font-weight: $font-weight-bold;
  }

  .mainResultLabel {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .subResultLabel {
    font-weight: $font-weight-normal;
  }
}

.statusMessage {
  padding: 9px 11px 9px;
  color: $color-4;
  font-size: $font-size-xs-small;
}

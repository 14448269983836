@import '../../../styles/settings';
@import '../../../styles/mixins';

.buttons {
  @include panelRow();

  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    width: 100%;
  }

  button:first-child {
    margin-right: 2px;
  }
}

@import '../settings';
@import '../mixins';

.infoPanel {
  background-color: $color-7;
  color: $color-4;
  display: block;
  font-family: $font-family-1;
  top: $mobile-height;
  width: 100%;
  z-index: 1;

  @media #{$mq-tablet} {
    position: relative;
    top: 0;
  }

  &._expanded {
    .metadata,
    .metadata._encounters {
      max-height: none;
      overflow: visible;
    }
  }

  .details,
  .metadata {
    display: flex;
    justify-content: space-between;
  }

  .details {
    flex-wrap: wrap;
  }

  .metadata {
    max-height: $vessel-info-height;
    overflow: hidden;
    padding: 20px 20px 15px;

    &._noPadding {
      padding: 0;
    }

    &._encounters {
      max-height: $vessel-info-height-encounters;
    }

    @media #{$mq-tablet} {
      max-height: none;
    }

    .info {
      width: 100%;

      .encountersData {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 8px;
        padding-left: 20px;

        @media #{$mq-tablet} {
          padding-left: 27px;
          max-height: none;
        }

        .arrowLink {
          color: $color-button-primary;
          text-transform: uppercase;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;

          .arrowSvg {
            margin-left: 5px;
            vertical-align: sub;
          }
        }
      }

      .header {
        padding: 10px 25px;
        font-size: $font-size-xs-small;
        display: flex;
        border-bottom: 1px solid $color-fade-blue;

        .title {
          display: flex;
          align-items: center;

          .encountersTitle {
            font-weight: bold;
            padding-left: 7px;
          }
        }

        .duration {
          padding-right: 20px;

          .durationLabel {
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }


      .vesselHeader {
        &::before {
          display: inline-block;
          content: "";
          margin-right: 10px;

          @include line($color-red);
        }
      }

      .reeferHeader {
        &::before {
          display: inline-block;
          content: "";
          margin-right: 10px;

          @include line($color-salmon);
        }
      }
    }


    .rowInfo {
      display: flex;
      flex-basis: calc(50% - 10px);
      flex-direction: column;
      flex-wrap: wrap;
      padding: 4px 0 10px;

      &:nth-child(2n) {
        margin: 0 0 0 5px;
      }

      > .key,
      > .value {
        color: $color-4;
        font-size: $font-size-xxs-small;
      }

      > .key {
        font-weight: $font-weight-bold;
      }

      > .value {
        padding: 5px 0 0;
      }
    }


    .externalLink {
      color: $color-3;
      cursor: pointer;
      display: inline-block;
      font-family: $font-family-1;
      font-size: $font-size-xxs-small;
      margin: 15px 0 0;
      text-decoration: underline;
    }

    .linkList {
      display: flex;
      flex-wrap: wrap;

      > .linkListItem {
        width: calc(50% - 3px);

        &:nth-child(2n) {
          margin: 0 0 0 6px;
        }
      }

      > .linkListItem > .externalLink {
        margin: 10px 0 0;
      }
    }

    .zoom {
      color: $color-3;
      cursor: pointer;
      display: block;
      font-family: $font-family-1;
      text-decoration: underline;
    }
  }

  .layer {
    padding: 0 20px 20px;
    font-size: $font-size-xxs-small;
  }

  .actionIcons {
    right: 86px;
    display: flex;
    flex-direction: column;

    @media #{$mq-tablet} {
      right: 16px;
    }
  }

  .buttonsContainer {
    bottom: 0;
    display: flex;
    right: 8px;
    transform: translate(0, 30%);
    float: right !important;
    margin-left: 5px;
    padding-right: 10px;

    @media #{$mq-tablet} {
      padding-right: 0;
      position: absolute;
      bottom: auto;
      left: 96px;
      right: 0;
      top: 18px;
      transform: translate(-50%, -50%);
    }


    > button {
      position: relative;
      transform: none;

      &:not(:first-child) {
        margin: 0 0 0 10px;
      }
    }
  }

  .ovalContainer {
    width: 18px;
    height: 18px;
    border: solid 1px $color-salmon;
    border-radius: 50%;
    display: inline-block;

    .oval {
      width: 10px;
      height: 10px;
      margin: 3px;
      background-color: $color-salmon;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .backButton {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 14px;

    .ovalContainer {
      margin-left: 10px;
    }
  }

  .backIcon {
    @include arrow(8px, 2px, $color-4, 'left');

    vertical-align: text-bottom;
    pointer-events: none;
    margin-top: 6px;
    transform: rotate(45deg) translate(0, -50%);
  }

  .backText {
    color: $color-4;
    font-family: $font-family-1;
    font-size: $font-size-xxs-small;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-left: 10px;

    @media #{$mq-tablet} {
      font-size: $font-size-xxs-small;
    }
  }
}

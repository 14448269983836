@import '../../../styles/settings';

.mapContainer {
  height: calc(100% - #{$map-footer-height + $timebar-height});
  position: relative;
  z-index: 4;
  background-color: $color-1;

  @media screen and (min-width: 900px) {
    height: calc(100% - #{$map-footer-height + $timebar-height}); // footer mini height
  }

  &._noFooter {
    height: calc(100% - #{$timebar-height});
  }
}

.timebarContainer {
  bottom: $map-footer-height;
  height: $timebar-height;
  left: 0;
  width: 100%;
  z-index: 20;

  &._noFooter {
    bottom: 0;
  }
}

@import '../../settings';

:global .datepicker {
  height: 100%;
  position: relative;
  z-index: 20;

  .datepickerTitle {
    color: $color-34;
    position: relative;
    font-size: $font-size-tiny;
    font-weight: $font-weight-bold;
    padding-bottom: 20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 18px;
    width: 100%;

    @media #{$mq-mobile-large} {
      font-size: $font-size-xxs-small;
    }
  }

  .react-datepicker__input-container {
    height: 100%;
  }

  .datepickerInput {
    resize: none;
    color: rgba($color-3, 0.5);
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: $font-size-tiny;
    font-family: $font-family-1;
    font-weight: $font-weight-bold;
    height: 100%;
    padding-top: 36px;
    margin-top: 2px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    word-wrap: break-word;

    @media #{$mq-mobile-large} {
      font-size: $font-size-xxs-small;
    }
  }
}

:global .calendar {
  &.upLeftCalendar {
    left: -200px;

    .react-datepicker__triangle {
      left: 220px;
    }

    @media #{$mq-tablet} {
      left: -146px;

      .react-datepicker__triangle {
        left: 193px;
      }
    }
  }
}
